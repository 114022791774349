<template>
  <div class="coianter">
    <div class="return_back"><el-button type="primary" size="medium" @click="goback()">返回</el-button></div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="个人资料" name="first"><exportuser /></el-tab-pane>
      <el-tab-pane label="账号安全" name="second"><qiyeuser /></el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import exportuser from "./exportuser.vue";
import qiyeuser from "./qiyeuser.vue";
export default {
  components: { exportuser, qiyeuser },
  data() {
    return {
      activeName: "first",
    };
  },
  mounted() {

  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    handleClick(){

    }
  },
};
</script>
<style scoped>
.coianter {
  background-color: #ffffff;
  padding: 27px 37px;
  margin-top: 40px;
  margin-right: 20px;
    margin-left: 20px;
}
.coianter .return_back{
  display:flex;
  justify-content: flex-end;
}
</style>
