<template>
  <div>
    <div class="pititle">
      <div class="piconetn">
        <!-- 表格 -->
        <div class="pitable">
          <el-table :data="tableData" :row-class-name="tabRowClassName" style="width: 100%"
            :header-cell-style="{ background: '#3082E2', color: '#FFF' }">
            <el-table-column label="登录IP" prop="tableData.ip" align="center">
              <template slot-scope="{ row }">
                <span>{{ row.ip }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="created_at" label="登录时间" align="center">
            </el-table-column>
          </el-table>
        </div>
        <!-- 分页 -->
        <div class="pageTotal">
          <div class="block">
            <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="page" layout="prev, pager, next" :total="totalCount" :page-size="limit">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//getProjectList
import { logData } from "../../../../api/user";
export default {
  data() {
    return {
      //选中的表格数据
      form: {},
      page: 1,
      limit: 10,
      totalCount: 0,
      totalPage: 1,
      tableData: [],
      token: "",
      telephone: "",
      username: "",
      name: "",
      address: "",
    };
  },
  mounted() {
    this.logData();
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
    tabRowClassName({ rowIndex }) {
      let index = rowIndex + 1;
      if (index % 2 == 0) {
        return "warning-row";
      }
    },
    logData() {
      let data = {
        limit: this.limit,
        page: this.page,
        token: this.$route.query.token
      };
      logData(data).then(res => {
        this.tableData = res.data
        // console.log('tableData', this.tableData)
        // console.log('res', res)
      })
    },
    getToken() {
      this.token = this.$store.state.token; //获取cookie字符串
      // console.log(this.token);
      // this.getUserList();
    },
    //搜索
    search() {
      this.page = 1;
      this.getList();
    },
    //清空
    qingkong() {
      (this.username = ""),
        (this.name = ""),
        (this.telephone = ""),
        (this.address = ""),
        this.getList();
    },
    // 分页
    handleSizeChange(page) {
      // this.limit = page;
      this.logData();
      //console.log(`每页 ${page} 条`)
    },
    handleCurrentChange(val) {
      //console.log(`当前页: ${page}`)
      this.page = val;
      this.logData();
    },
    gotoStep(row) {
      let url = "/user/accontimInfo?id=" + row.id + "&token=" + row.token;
      return url;
    },
  },
};
</script>
<style scoped>
/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

/deep/.el-button--primary {
  background-color: #3082e2;
  border-color: #3082e2;
}

/deep/.el-button--primary:hover {
  background-color: #409eff;
  border-color: #409eff;
}

/deep/.el-input__suffix {
  right: 43px;
  transition: all 0.3s;
  border-left: 1px solid #c0c4cc;
}

/deep/.el-table__row .el-table__row--striped {
  background-color: rgba(48, 130, 226, 0.1);
}

/deep/ .el-table th.el-table__cell>.cell {
  min-width: 100px;
}

/deep/ .el-table th.el-table__cell.is-leaf,
/deep/ .el-table td.el-table__cell {
  border-bottom: 0;
}

/deep/.el-form-item__label {
  font-family: Adobe Heiti Std;
  color: #2d2d2d;
}

/deep/ .litop .el-input {
  width: 245px;
}

/deep/ .litop .el-input__inner {
  width: 204px;
}

/deep/ .el-table th.el-table__cell>.cell {
  font-size: 16px;
}

/deep/.el-table .warning-row {
  background: rgba(48, 130, 226, 0.1);
}

.pitable {
  /*height: 60vh;*/
}

.litop {
  margin: 20px 24px;
}

.litop span {
  color: #3086fb;
  margin-left: 10px;
  line-height: 40px;
}

.pageTotal {
  display: flex;
  flex-direction: row-reverse;
  margin: 38px 0;
}

.prbtn {
  display: flex;
  flex-direction: row-reverse;
}

.linkto {
  text-decoration: none;
  color: #3082e2;
}

.linkto>span:hover {
  color: #84b5ec;
}

/* 新改 */
.zhidingsearch {
  background-color: #ffffff;
  padding: 1px 15px;
  margin: 20px 0 10px 0;
}

.piconetn {
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0px 3px 9px 1px #eeeeee;
}

.flexrow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 20px;
}

.tilelft {
  font-size: 22px;
  font-weight: bold;
  color: #1a1a1a;
}</style>
